define('ember-useragent/instance-initializers/user-agent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  /* global FastBoot */

  function getUserAgent(appInstance) {
    if (typeof FastBoot === 'undefined') {
      const userAgent = Ember.get(window, 'navigator.userAgent');
      (true && !(userAgent) && Ember.assert('No `userAgent` present in window.navigator', userAgent));

      return userAgent;
    } else {
      const fastboot = appInstance.lookup('service:fastboot');
      const headers = Ember.get(fastboot, 'request.headers');
      const userAgent = headers.get('user-agent');
      (true && !(userAgent) && Ember.assert('No `user-agent` present in FastBoot headers.', userAgent));

      return userAgent;
    }
  }

  function initialize(appInstance) {
    const service = appInstance.lookup('service:user-agent');
    Ember.set(service, 'userAgent', getUserAgent(appInstance));
  }

  exports.default = {
    initialize
  };
});